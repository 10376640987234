<!--
 * @Author: yanmin
 * @Description: 入场页面
 * @Date: 2022-04-14 11:15:03
 * @LastEditTime: 2023-07-27 15:39:45
 * @FilePath: \yf_car\src\views\guardPayment.vue
-->
<!--  -->
<template>
    <!-- <div class='guardPayment'> -->
    <div class="g-tips-box">
        <img src="../assets/images/tips-img-payok.png" />
        <strong>支付成功</strong>
        <div v-if="isTime">
            <span>
                请在15分钟 <i>（{{minute}}:{{second}}）</i> 内离场避免重新计费；如需发票请至人工服务台领取
            </span>
            <div class="g-btn f-btncolor-blue" v-if="isPayOut" @click="againPay()">超时请重新支付</div>
            <div class="g-btn" @click="againPay()">返回</div>
        </div>
        <!-- <div class="g-foot-box">
            <p class="u-updata-tips" v-if="!isTime">若不抬杆，请拨打停车场服务热线</p>
            <footdiv></footdiv>
        </div> -->
    </div>
    <!-- </div> -->
</template>

<script>
    import {
        removeObjNull,
        browserName,
        urlEncode,
        urlParse
    } from '@/utils/base'
    import {
        setToken,
        getToken,
        removeToken,
        setSmarkId,
        removeSmarkId,
        setParklogId,
        removeParklogId,
    } from '@/utils/auth'
    export default {
        components: {},
        data() {
            return {
                minute: 0,
                second: 0,
                isPayOut: false, //是否超时
                isTime: false, //是否预支付
                car_number: "", //车牌号
                smartpark_id: "", //园区ID
            };
        },
        computed: {},
        watch: {},
        methods: {
            //点击重新支付，返回
            againPay() {
                // window.location.href = pathUrl + "/ypayment.html?ypaydata=" + this.car_number + "-" + this.smartpark_id;
            },
            //倒计时方法
            setTimeFn() {
                // let payTime = window.setInterval(() => {
                //     if (this.second > 0) {
                //         this.second--
                //     } else {
                //         this.second = 60;
                //         this.minute--;
                //     }
                //     if (this.minute == 0) {
                //         this.second = 0;
                //         this.isPayOut = true;
                //         clearInterval(payTime);
                //     }
                // }, 1000)
            },
        },
        created() {
            //获取url参数
            // this.car_number = getQueryVariable("car_number");
            // this.smartpark_id = getQueryVariable("smartpark_id");
            // axios.get(ajaxAllUrl.ypaymoneyUrl + "?car_number=" + this.car_number + "&smartpark_id=" + this.smartpark_id)
            //     .then(res => {
            //         var timestamp = new Date().getTime() / 1000; //获取当前时间戳
            //         var freeTimeEnd = res.data.data.free_time_end; //获取过期时间
            //         if (freeTimeEnd == 0 || res.data.code == 0) { //不是预支付
            //             this.isTime = false;
            //         } else {
            //             this.isTime = true;
            //             var timeRemaining = Math.floor((freeTimeEnd - timestamp)); //剩余时间
            //             if (timeRemaining <= 0) {
            //                 this.isPayOut = true;
            //             } else {
            //                 this.minute = Math.floor(timeRemaining / 60); //获取分钟
            //                 this.second = timeRemaining % 60; //获取秒
            //                 this.isTime = true;
            //                 this.setTimeFn()
            //             }
            //         }
            //     })
            //     .catch(function (error) {
            //         console.log(error)
            //     })
        },
        mounted() {

        },
        beforeCreate() {},
        beforeMount() {},
        beforeUpdate() {},
        updated() {},
        beforeDestroy() {},
        destroyed() {},
        activated() {},
    }
</script>
<style lang='scss' scoped>

</style>